<template>
  <auth>
    <template #auth-title>
      Enter your new password
    </template>

    <template #form>
      <b-field
        label="Password"
        class="mb-4"
      >
        <b-input
          v-model="auth.password"
          icon="lock"
          type="password"
          password-reveal
          placeholder="************"
          @keyup.native.enter="changePassword()"
        />
      </b-field>
      <b-field label="Repeat the password">
        <b-input
          v-model="auth.repeat_password"
          icon="lock"
          type="password"
          password-reveal
          placeholder="************"
          @keyup.native.enter="changePassword()"
        />
      </b-field>
    </template>

    <template
      v-if="!arePasswordsValid && isSubmitted"
      #error-message
    >
      Passwords do not match. Please check your input and try again.
    </template>
    <template
      v-if="isPasswordChanged"
      #success-message
    >
      Password was successfully changed. You can login now. <router-link to="/login">
        Back to login
      </router-link>
    </template>

    <template #auth-additional-options>
      <div class="column is-12">
        <router-link
          class="is-pulled-right is-size-7"
          to="/login"
        >
          Back to login
        </router-link>
      </div>
    </template>

    <template #action-button>
      <b-button
        expanded
        type="is-primary"
        @click="changePassword()"
      >
        Confirm the password change
      </b-button>
    </template>

    <template #info />
  </auth>
</template>

<script>
import { useRoute } from '@/hooks/vueRouter';
import { resetPasswordService } from '@/services/auth-service/authRequests';
import { ref, reactive, computed } from '@vue/composition-api';
import Auth from './../components/Auth';

export default {
    components: {
        Auth
    },
    setup () {
        // compose hooks
        const route = useRoute();

        /***** reset password logic start *****/
        const arePasswordsValid = computed(() => auth.password === auth.repeat_password);
        const isPasswordChanged = ref(false);
        const isSubmitted = ref(false);
        const auth = reactive({
            password: '',
            repeat_password: ''
        });
        const changePassword = async () => {
            try {
                isSubmitted.value = true;
                isPasswordChanged.value = false;
                if (arePasswordsValid.value) {
                    await resetPasswordService({
                        token: route.params.token,
                        password: auth.password
                    });
                    isPasswordChanged.value = true;
                }
            } catch (err) {
                console.error(err);
            }
        };
        /** logic exposed to template */
        const exposedResetPasswordLogic = {
            arePasswordsValid,
            isPasswordChanged,
            isSubmitted,
            auth,
            changePassword
        };
        /***** reset password logic end *****/

        return {
            ...exposedResetPasswordLogic
        };
    }
};
</script>
